<template>
	<div>
		<e-select
			track-by="id"
			label="name"
			:options="countries_iso_array"
			:searchable="true"
			:allow-empty="false"
			:show-labels="false"
			:search="true"
			:placeholder="labelTitle"
			:deselectLabel="deselectLabel"
			:selectedLabel="selectedLabel"
			:selectLabel="enter_to_select"
		    :disabled="disabled"
			@select="country"
			v-model="internalValue"
		>
			<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
			<template slot="option" slot-scope="props">
				{{ props.option.name }}
			</template>
			<template slot="noOptions">{{ $t('global.list_empty') }}</template>
		</e-select>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Countries from '@/assets/lang/countries/countries'
	
	export default {
		props: {
			value:{
				type: [Number, String],
				default: () => (75)
			},
            disabled: {
                type: Boolean,
                default: () => (false)
            },
			restricted_table: {
				type: Array,
				default: () => ([])
			}
		},
		data () {
			return {
				indicatifs: {},
				countries: {},
				countries_iso: {},
				countries_iso_array: [],
				countries_iso_array_temp: [],
				labelTitle: this.getTrad("global.press_enter_to_remove"),
				deselectLabel: this.getTrad("global.selected_label"),
				selectedLabel: this.getTrad("global.press_enter_to_select"),
				enter_to_select: this.getTrad("compta.search_country"),
			}
		},
		created() {
			this.countries = Countries[Vue.i18n.locale()].label
			this.countries_iso = Countries[Vue.i18n.locale()].iso_3

			let countries_values = Object.values(this.countries)
			let countries_iso_values = Object.values(this.countries_iso)

			let temp = []
			for (let i = 0; i < countries_values.length; i++) {
				temp.push(countries_values[i]+' - '+countries_iso_values[i])
			}

			let countries_keys = Object.keys(this.countries)
			let countries_formatted = Object.fromEntries(countries_keys.map((_, i) => [countries_keys[i], temp[i]]))
			for (let [key, value] of Object.entries(countries_formatted))
			{
				if(this.restricted_table.length == 0) {
					this.countries_iso_array_temp.push({
						id: key,
						name: value
					})
				}
				else if (this.restricted_table.includes(parseInt(key))) {
					this.countries_iso_array_temp.push({
						id: key,
						name: value
					})
				}
			}
			this.countries_iso_array = this.countries_iso_array_temp.sort((a,b) => {
				// return a.name - b.name;
				var x = this.accentsTidy(a.name.toLowerCase());
			    var y = this.accentsTidy(b.name.toLowerCase());
			    return x < y ? -1 : x > y ? 1 : 0;
			});
		},
		methods: {
			country(selectdOption, id){
				this.$emit('input', selectdOption.id )
			}
		},
		computed: {
			internalValue:{
				get () {
					if(this.value === 0 || !this.value) return {}
						
					const country = this.countries_iso_array.filter(country => {
						return country.id == this.value
					})[0]

					return {
						id: this.value,
						name: country.name
					}
				},
				set (val) {
					
				}
			},
		}
	}
</script>
